import {
  AncillaryProduct,
  AvailabilityError,
  LuggageOptions,
  QueryError,
  UnionError,
} from '@AuroraTypes';

export const isApplePaySupported = (): boolean =>
  typeof window !== 'undefined' &&
  window.location.protocol === 'https:' &&
  // @ts-ignore
  window.ApplePaySession &&
  // @ts-ignore
  window.ApplePaySession.canMakePayments() &&
  // @ts-ignore
  window.ApplePaySession.supportsVersion(3);

export function isQueryError<T>(orderResult: T | QueryError): orderResult is QueryError {
  return (orderResult as QueryError).code !== undefined;
}

export function isAvailabilityError<T>(
  orderResult: T | UnionError,
): orderResult is AvailabilityError {
  return (orderResult as UnionError).errorType === 'AvailabilityError';
}

// Capture QueryError, AvailabilityError and other types of errors we might add in the future
export function isUnionError<T>(orderResult: T | UnionError): orderResult is UnionError {
  return (orderResult as UnionError)?.code !== undefined;
}

export const extractIncludedLuggageTypes = (luggageOptions: LuggageOptions) => {
  const { cabinBagsDetails, mode } = luggageOptions;
  const cabinLuggageIncluded =
    !!cabinBagsDetails?.largeCabinBagAllowance && cabinBagsDetails?.largeCabinBagAllowance > 0;
  const holdLuggageIncluded = mode === 'INCLUDED';

  return {
    cabinLuggageIncluded,
    holdLuggageIncluded,
  };
};

export const getAncillaryPrice = (product: AncillaryProduct): number => {
  /* there can't be more than one defined */
  return product.seats?.price || product.toggle?.price || product.radio?.price || 0;
};
